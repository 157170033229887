<template>
  <v-container
    fluid
  >
    <v-row
      class="docs align-start justify-center"
      wrap
    >
      <v-col
        class="ma-3"
        cols="8"
      >
        <welcome-card />

        <doc-card
          title="Features"
          type="feature"
        />

        <doc-card
          title="Saved Filters"
          type="filter"
        />

        <doc-card
          title="Release Notes"
          type="release"
        />

        <doc-card
          title="Roadmaps"
          type="roadmap"
        />

        <doc-card
          title="Other"
          type="legal"
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: 'Docs',
  components: {
    DocCard: () => import('../components/DocCard.vue'),
    WelcomeCard: () => import('../components/WelcomeCard.vue'),
  },
  props: {
    query: {
      type: Object,
      default: () => ({}),
    },
    reportId: {
      type: String,
      default: null,
    },
    reportName: {
      type: String,
      default: 'docs',
    },
    subId: {
      type: String,
      default: '*',
    },
  },
}
</script>

<style lang="scss" scoped>
@import "~src/styles/variables";

.docs {
  .docs-card {
    border-radius: 6px;
    box-shadow: 0 2px 6px 0 rgba(0, 148, 255, 0.21);
  }

  .card-title {
    font-size: medium;
    font-weight: bold;
    letter-spacing: 0.69px;
    line-height: 29px;
  }

  .welcome-card {
    .card-title {
       color: $color-monochrome-brightblue;
      justify-content: center;
    }
  }
}
</style>
